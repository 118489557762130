.dark-mode .footer .footer-social-icons a {
  background-color: #1b1b1b;
  color: #818a91;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.light-mode .footer .footer-social-icons a {
  background-color: #aca9a9;
  color: #e4e5e6;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.footer-social-icons a.linkedin:hover {
  background-color: #007bb6;
  color: #fff;
}

.footer-social-icons a.instagram:hover {
  background-color: #ca7411;
  color: #fff;
}

.gci {
  text-decoration: none;
  color: inherit;
}
.gci:visited {
  text-decoration: none;
  color: inherit;
}
.gci:hover {
  text-decoration: none;
  color: inherit;
}
.gci:focus {
  text-decoration: none;
  color: inherit;
}
