.icons {
  animation: beat 7s ease-in-out infinite;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
}
