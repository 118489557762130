.title-separator {
  height: 3px;
  width: 36px;
  background-color: #0fd24f;
}

.title-separator-second {
  height: 3px;
  width: 10px;
  background-color: #0fd24f;
  opacity: 0.8;
  margin-left: 0.5rem;
}

.title-separator-third {
  height: 3px;
  width: 5px;
  background-color: #0fd24f;
  opacity: 0.6;
  margin-left: 0.5rem;
}

.video-play-button {
  position: relative;
  background-color: rgb(231, 241, 223);
}
.video-play-button svg {
  position: absolute;
  z-index: 3;
  left: calc(43%);
}
.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100px;
  background: #fff;
  opacity: 0.6;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  width: 80%;
  z-index: 1;
  height: 80px;
  background: rgb(255, 255, 255);
  opacity: 0.6;
  border-radius: 50%;
  transition: all 200ms;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
