.inputs {
  padding: 1rem;
  font-family: inherit;
  margin: 1rem 0 0;
}

.inputs.text_input {
  padding: 1rem 1rem 4rem;
}

.button {
  text-align: center;
  position: relative;
  display: block;
  width: 200px;
  height: 36px;
  border-radius: 5px;
  background-color: #0fd24f;
  border: solid 1px transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  transition: 1s all ease-in-out;
  margin: 0.5rem 0 0;
}

.button:hover {
  background-color: #0b580f;
  transition: 0.3s all ease-in-out;
}

.underline_contactos::after {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 12px;
  z-index: -1;
  background-color: #0fd24f;
}
