.CTA-button {
  padding: 1rem 3rem;
  border: none;
  color: white;
  background-color: #002b5e;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: background-color 1s ease;
  cursor: pointer;
}

.CTA-button:hover {
  background-color: #01244e;
}
