.Earth_wrapper {
  display: flex;
  position: relative;
  justify-content: center;
}

.text {
  position: absolute;
  width: fit-content;
  top: 15rem;
  left: 14%;
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: 99;
}

@media only screen and (max-width: 1440px) {
  .text {
    left: 5%;
  }
}

html {
  scroll-behavior: smooth;
}

.divider {
  margin: 0 5px;
  position: relative;
  width: calc(100% - 5%);
  height: 5px;
  background-color: #0fd24f;
  border-radius: 25px;
}

.divider2 {
  margin: 0 5px;
  position: relative;
  width: 50px;
  height: 5px;
  background-color: #0fd24f;
  border-radius: 25px;
}
.divider3 {
  margin: 0 5px;
  position: relative;
  width: 10px;
  height: 5px;
  background-color: #0fd24f;
  border-radius: 25px;
}

.canvas_wrapper {
  max-width: 1440px;
  width: 100vw;
  height: 100vh;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.show {
  opacity: 1;
  transition: opacity 3s ease;
}

.info_title {
  font-size: 35px;
  position: absolute;
  width: 400px;
  text-align: center;
}

.info_items {
  color: white;
  font-size: 35px;
  position: absolute;
}

.underline::after {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 2px;
  z-index: -1;
  background-color: #0fd24f;
}

.solucoes-underline::after {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 2px;
  z-index: -1;
  background-color: #0fd24f;
}
