
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dark-mode{
  background-color: black;
  color: white;
}

.light-mode {
  background-color: white;
  color: black;
}

.nossomanifesto-heading{
  transition: 1s color ease;
  cursor: inherit;
  color: #0fd24f;;
}

.contacto-heading{
  transition: 1s color ease;
  cursor: inherit;
  color: #0fd24f;;
}

.solucoes-heading{
  transition: 1s color ease;
  cursor: inherit;
  color: #0fd24f;;
}

.equipa-heading{
  transition: 1s color ease;
  cursor: inherit;
  color: #05C8A1;
}


.manifesto-separator{
  height: 100%;
  width: 2px;
  background-color: gray;
}

.manifesto-underline::after{
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  bottom: 2px;
  z-index: -1;
  background-color: #0fd24f;;
}

.portfolio-description ul {
  margin: 2% 0;
}

.portfolio-description ul li{
  margin: 1% 0;
}

.parallax-wrapper{
  overflow-y: hidden;
}

.portfolio-button{
  padding:1rem 3rem;
  border: none; 
  color: white; 
  background-color: #0fd24f;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: background-color 1s ease;
  cursor: pointer;

}

.portfolio-button:hover{
  background-color: #12b147;
}

